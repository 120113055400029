<template>
    <div class="licenses">
        <v-row class="mb-4">
            <v-col cols="6" sm="6">
                <v-text-field
                    v-model="filter"
                    :label="$t('licenses.search')"
                    append-icon="far fa-search"
                    clearable
                    clear-icon="mdi mdi-magnify"
                    single-line
                    hide-details
                    @input="filterlicenses()"
                />
            </v-col>
        </v-row>

        <v-data-table
            class="operations striped"
            :headers="headers"
            :items="licenses"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options': [20, 50, 100, 200]}"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >
            <template v-slot:item="{headers, item }">
                <tr>
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.venue }}</td>
                    <td>{{ item.tax_id_number}} </td>
                    <td>{{ item.started_at | dateFromUtc}} </td>
                    <td>{{ item.expires_at | dateFromUtc}} </td>
                    <td>{{ item.notified_online_at | dateFromUtc}} </td>
                    <td v-if="item.reseller">{{ item.reseller.name }} </td>
                    <td v-else></td>
                    <td class="w60" align="center">
                        <v-menu bottom transition="slide-y-transition">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon>
                                    <v-icon >mdi mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <template v-if="$store.state.user.role === 'admin' || $store.state.user.role === 'main_reseller'">
                                    <v-list-item
                                        :key="1"
                                        @click="openDebugPage(item)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-console</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>{{$t('general.debug')}}</v-list-item-title>
                                    </v-list-item>
                                    <v-divider />
                                </template>

                                <template>
                                    <v-list-item
                                        :key="2"
                                        @click="openKeyChangeDialog(item)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-key-variant</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>{{$t('lpfr.keyChange')}}</v-list-item-title>
                                    </v-list-item>
                                </template>

                                <template v-if="$store.state.user.role === 'admin'">
                                    <v-divider />
                                    
                                    <v-list-item
                                        :key="3"
                                        @click="showLicenseLogs(item)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-information</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>
                                            {{$t('general.logs')}}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog
            v-model="show.editSerialKey"
            width="500"
        >
            <div class="dialog">
                <div class="dialogHeader">
                    {{ $t('lpfr.keyChange') }}
                </div>
                    <div class="dialogContent" v-if="currLicense">
                            <v-text-field
                                :label="$t('lpfr.serialKey')"
                                v-model="currLicense.serial_key"
                                prepend-icon="mdi mdi-key-variant"
                                disabled
                            >
                            </v-text-field>

                            <v-text-field
                                :label="$t('lpfr.newSerialKey')"
                                v-model="form.newSerialKey"
                                :error-messages="form.$errors.newSerialKey"
                                prepend-icon="mdi mdi-key-variant"
                            >
                            </v-text-field>

                        <div class="d-flex justify-center mb-1 mt-1">
                            <v-btn
                                color="primary"
                                outlined
                                @click="saveSerialKey"
                            >
                                {{ $t('general.save') }}
                            </v-btn>
                        </div>
                    </div>

            </div>
        </v-dialog>

        <v-dialog
            width="900"
            v-model="show.logs"
        >
            <div class="dialog-header">
                {{$t('general.logs')}}
            </div>
            <div class="dialog-body overflow-auto" >
                <v-timeline v-if="logs.length > 0">
                    <v-timeline-item
                        color="black"
                        v-for="log in logs"
                        :key="log.id"
                    >
                        <span slot="opposite">{{ log.created_at | dateFromUtc }}</span>
                        <v-card class="elevation-2">
                            <v-card-title class="text-h6">
                                {{ log.description }}
                            </v-card-title>
                            <v-card-text>
                                <div v-if="log.description == 'created'">
                                    <div>
                                        {{ $t('general.user') + " " + log.causer.email + " " + $t('log.is_created') }}
                                    </div>
                                    <vue-json-pretty :data="log.properties.attributes" />
                                </div>
                                <div v-if="log.description == 'updated'">
                                    <div>
                                        {{ $t('general.user') + " " + log.causer.email + " " + $t('log.is_updated') }}
                                    </div>
                                    <vue-json-pretty :data="log.properties.old" />
                                    {{ $t('log.to') }}
                                    <vue-json-pretty :data="log.properties.attributes" />
                                </div>
                                <div v-if="log.description == 'deleted'">
                                    {{ $t('general.user') + " " + log.causer.email + " " + $t('log.is_deleted') }}
                                    <vue-json-pretty :data="log.properties.attributes" />
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
                <div v-else > {{$t('log.no_logs')}} </div>
            </div>
        </v-dialog>

    </div>
</template>

<script>
import _ from "lodash";
import CompanyForm from "@/views/admin/Company/CompanyForm";
import VueJsonPretty from "vue-json-pretty";
import * as rules from "vuelidate/lib/validators";

export default {
    components: {
        VueJsonPretty,
        CompanyForm,
    },
    created() {
        this.form = this.createForm();
    },

    data() {
        return {
            form: null,
            loading: false,
            details: false,
            tab: 0,
            logs: [],
            total: 0,
            pagination: {
                sortBy: ["created_at"],
                itemsPerPage: 100,
                page: 1,
                sortDesc: [true],
            },

            headers: [
                { text: this.$t('general.name'), value: "" },
                { text: this.$t('general.email'), value: "" },
                { text: this.$t('general.venue'), value: "" },
                { text: this.$t('general.tax_id_number'), value: "" },
                { text: this.$t('general.started_at'), value: "" },
                { text: this.$t('general.expires_at'), value: "" },
                { text: this.$t('general.notified_online_at'), value: "" },
                { text: this.$t('general.reseller'), value: "" },
                { text: "", value: "options" },
            ],

            filter: null,
            status: "failed",
            type: null,
            licenses: [],
            curCompany: null,
            currLicense: null,
            show: {
                editCompany: false,
                editSerialKey: false,
                logs: false
            },
        };
    },

    validations: {
        form: {
            newSerialKey: { required: rules.required },
        },
    },

    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getAlllicenses();
            },
        },
    },

    methods: {

        createForm() {
            return new CodForm(this, "form", {
                newSerialKey: null,
            });
        },

        /**
         * Get Paginated, Filtered and Sorted transactions from server
         */
        getAlllicenses() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : "created_at",
                sortOrder: sortDesc[0] ? "desc" : "asc",
            };

            this.filter = this.$route.query.q;

            if (this.filter) params["filter"] = this.filter;
            if (this.reseller) params["reseller"] = this.reseller;

            this.loading = true;
            ApiService.get("/admin/esdc/licenses", { params: params }).then(
                response => {

                    this.loading = false;
                    this.licenses = response.data.data.map((license) => {
                        return license;
                    });
                    this.total = response.data.total;

                }
            ).catch(
                error => {
                  if (error.response.status === 403) {
                    this.$snackbar('You are not authorized to perform this action.', 'error');
                  }
                }
            );
        },
        /**
         * Filter licenses
         */
        filterlicenses: _.debounce(function () {

            if(this.filter == '' || !this.filter) this.$router.push({query: null})
            else this.$router.push( {query: {q: this.filter}})

            this.getAlllicenses();

        }, 500),

        openDebugPage(license){

            this.$store.state.licenseDetails = license;

            this.$router.push({name: "debug"})
        },

        openKeyChangeDialog(license){
            this.form = this.createForm()
            this.show.editSerialKey = true
            this.currLicense = license
        },

        saveSerialKey(){

            if (!this.form.validate()) return;

            ApiService.post(`/admin/esdc/licenses/${this.currLicense.id}`,
                { serialKey: this.form.newSerialKey }
            ).then(
                response => {
                    this.$snackbar("Serial key updated", "success");
                    this.getAlllicenses()
                    this.show.editSerialKey = false
                }
            ).catch(
                error => {
                    if (error.response.status === 403) {
                        this.$snackbar('You are not authorized to perform this action.', 'error');
                    }else{
                        this.$snackbar(error.response.data.message, 'error');
                    }
                }
            );

        },


        async showLicenseLogs(item){
            const response = await ApiService.get(`/admin/esdc/licenses/${item.venue_id}/activity`);
            this.logs = response.data.activities.map(item => {
                if(item.causer == null){
                    item.causer = {email: 'system'}
                }
                return item
            });
            this.show.logs = true
        }
    },
};
</script>

<style lang="scss" scoped>
.licenses {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;
}

.edit-company, .dialog {
    .edit-company-header , .dialogHeader {
        padding: 16px;
        background: #363c4a;
        color: white;
    }

    .edit-company-content {
        padding: 16px;
        background: white;
    }

    .dialogContent {
        padding: 16px 28px;
        background: white;
    }
}
</style>
