import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tenants"},[_c(VToolbar,{attrs:{"flat":""}},[_c(VTextField,{staticClass:"search",attrs:{"label":_vm.$t('customers.search'),"append-icon":"far fa-search","clearable":"","clear-icon":"mdi mdi-magnify","single-line":"","hide-details":""},on:{"input":function($event){return _vm.filterTenants()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VSelect,{staticStyle:{"max-width":"400px"},attrs:{"items":_vm.filters,"item-text":"name","item-value":"code","chips":"","clearable":"","label":_vm.$t('general.filters'),"multiple":""},on:{"change":_vm.getTenants},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0 || index === 1)?_c(VChip,[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),_vm._v(" "),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("\n                    (+"+_vm._s(_vm.selectedFilters.length - 2)+" "+_vm._s(_vm.$t('general.more'))+")\n                ")]):_vm._e()]}}]),model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}})],1),_vm._v(" "),_c(VDataTable,{staticClass:"operations striped",attrs:{"headers":_vm.headers,"items":_vm.tenants,"options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{ 'items-per-page-options': [50, 100, 200, 500] },"items-per-page":_vm.pagination.itemsPerPage,"loading":_vm.loading,"mobile-breakpoint":0},on:{"update:options":function($event){_vm.pagination=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.reseller ? item.reseller.name : ''))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.email))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.app))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.venue ? item.venue.name : ''))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.venue ? item.venue.country + ' ' + item.venue.address : ''))]),_vm._v(" "),(_vm.isCountry('ME'))?_c('td',[_vm._v(_vm._s(_vm._f("dateFromUtc")(item.cert_expire_at)))]):_vm._e(),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("dateFromUtc")(item.created_at)))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("dateFromUtc")(item.trial_ends_at)))]),_vm._v(" "),_c('td',{staticClass:"w60",attrs:{"align":"center"}},[_c(VMenu,{attrs:{"bottom":"","close-on-content-click":false,"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[(_vm.impersonatingTenantId === item.id)?_c(VIcon,[_vm._v("mdi mdi-account")]):_c(VIcon,[_vm._v("mdi mdi-dots-horizontal")])],1)]}}],null,true)},[_vm._v(" "),_c(VList,{staticClass:"pa-0"},[_vm._l((_vm.menuList),function(option,i){return [(i && option.enabled(item))?_c(VDivider):_vm._e(),_vm._v(" "),(option.enabled(item))?_c(VListItem,{key:i,attrs:{"link":""},on:{"click":function($event){return option.action(item, option.component)}}},[(option.children)?_c(VMenu,{attrs:{"open-on-hover":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex w-100"},'div',attrs,false),on),[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi mdi-chevron-left")])],1),_vm._v(" "),_c(VListItemTitle,{class:option.class},[_vm._v("\n                                                    "+_vm._s(option.title(item))+"\n                                                ")]),_vm._v(" "),_c(VListItemAction,[_c(VIcon,{class:option.class},[_vm._v(_vm._s(option.icon))])],1)],1)]}}],null,true)},[_vm._v(" "),_c(VList,{staticClass:"pa-0"},[_vm._l((option.children),function(child,j){return [(j && child.enabled(item))?_c(VDivider):_vm._e(),_vm._v(" "),(child.enabled(item))?_c(VListItem,{key:j,attrs:{"link":""},on:{"click":function($event){return child.action(item, child.component)}}},[_c(VListItemAction,[_c(VIcon,{class:child.class},[_vm._v(_vm._s(child.icon))])],1),_vm._v(" "),_c(VListItemTitle,{class:child.class},[_vm._v("\n                                                        "+_vm._s(child.title(item))+"\n                                                    ")])],1):_vm._e()]})],2)],1):[_c(VListItemTitle,{class:option.class},[_vm._v("\n                                            "+_vm._s(option.title(item))+"\n                                        ")]),_vm._v(" "),_c(VListItemAction,[_c(VIcon,{class:option.class},[_vm._v(_vm._s(option.icon))])],1)]],2):_vm._e()]})],2)],1)],1)])]}}])}),_vm._v(" "),(_vm.isOpen)?_c(_vm.curDialog,{tag:"component",attrs:{"curTenant":_vm.curTenant},on:{"success":_vm.success},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }