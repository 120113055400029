<template>
    <div class="companies">
        <v-row class="mb-4">
            <v-col cols="6" sm="6">
                <v-text-field
                    v-model="filter"
                    :label="$t('companies.search_company')"
                    append-icon="far fa-search"
                    clearable
                    clear-icon="mdi mdi-magnify"
                    single-line
                    hide-details
                    @input="filterCompanies()"
                />
            </v-col>
            <v-col cols="6" sm="6">
                <v-autocomplete
                    :items="resellers"
                    v-model="reseller"
                    :label="$t('companies.search_reseller')"
                    clearable
                >
                </v-autocomplete>
            </v-col>

        </v-row>

        <v-data-table
            class="operations striped"
            :headers="headers"
            :items="companies"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options': [20, 50, 100, 200, 500, 1000]}"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >

            <template v-slot:item="{headers, item, index }">
                <tr v-if="index === 0" style="background-color:#363C4A !important; color: white;">
                    <td class="sum-td">Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="sum-td">{{totalSum('tenants_count')}}</td>
                    <td class="sum-td">{{totalSum('invoices_count')}}</td>
                    <td class="sum-td">{{totalSum('cash_register_count')}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{ item.tax_id_number}} </td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.amount_due | twoDecimals }}</td>
                    <td v-if="item.tenants[0]">
                        <template v-if="item.tenants[0].reseller">{{ item.tenants[0].reseller.name }}</template>
                    </td>
                    <td v-else></td>
                    <td>{{ item.tenants_count }}</td>
                    <td>{{ item.invoices_count }}</td>
                    <td>{{ item.cash_register_count }}</td>
                    <td class="w60" align="center">
                        <v-menu bottom transition="slide-y-transition">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon>
                                    <v-icon >mdi mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <template>
                                    <v-list-item
                                        :key="1"
                                        @click="showDetails(item)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-eye-outline</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>{{$t('general.details')}}</v-list-item-title>
                                    </v-list-item>
                                    <v-divider />
                                    <!-- Admin and Geneko reseller can edit -->
                                    <v-list-item v-if="$store.state.user.role === 'admin' || $store.state.user.reseller.hash === 'yr025xq707'"
                                        :key="2"
                                        @click="editCompany(item)"

                                    >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon>mdi mdi-account-edit</v-icon>
                                            </v-list-item-action>
                                        <v-list-item-title>{{$t('general.edit')}}</v-list-item-title>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item
                                        v-if="$store.state.user.role === 'admin'"
                                        :key="7"
                                        @click="showCompanyLogs(item.id)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-information</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>
                                            {{$t('general.logs')}}
                                        </v-list-item-title>
                                        <v-divider />
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>

                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog
            v-model="show.editCompany"
            width="500"
        >
            <div class="edit-company">
                <div class="edit-company-header">
                    Edit company
                </div>
                <company-form
                    class="edit-company-content"
                    :company="curCompany"
                    @save="onCompanyUpdate"
                />
            </div>
        </v-dialog>
        <v-dialog
            width="900"
            v-model="show.logs"
        >
            <div class="dialog-header">
                {{$t('general.logs')}}
            </div>
            <div class="dialog-body overflow-auto" >
                <Activity :logs="logs" />
            </div>
        </v-dialog>

    </div>
</template>

<script>
import _ from "lodash";
import CompanyForm from "@/views/admin/Company/CompanyForm";
import Activity from "../Activity/Activity.vue";

export default {
    components: {
        Activity,
        CompanyForm,
    },
    created() {
        this.getResellers();
    },

    data() {
        return {
            loading: false,
            details: false,
            tab: 0,

            total: 0,
            pagination: {
                sortBy: ["created_at"],
                itemsPerPage: 100,
                page: 1,
                sortDesc: [true],
            },

            headers: [
                { text: this.$t('companies.table.PIB'), value: "tax_id_number" },
                { text: this.$t('companies.table.name'), value: "name" },
                { text: this.$t('companies.table.amount_due'), value: "amount_due" },
                { text: this.$t('general.reseller'), value: "tenants[0].promo_code" },
                { text: this.$t('companies.table.number_of_tenants'), value: "tenants_count" },
                { text: this.$t('companies.table.number_of_invoices'), value: "invoices_count" },
                { text: this.$t('companies.table.cash_register_count'), value: "invoices_count" },
                { text: "", value: "options" },
            ],

            filter: null,
            reseller: null,
            status: "failed",
            type: null,
            companies: [],
            curCompany: null,
            show: {
                editCompany: false,
                logs:false
            },
            resellers: [],
            logs:[]
        };
    },

    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getAllCompanies();
            },
        },
        status() {
            this.getAllCompanies();
        },

        type() {
            this.getAllCompanies();
        },
        reseller() {
           this.getAllCompanies();
        },
    },

    methods: {
        /**
         * Get Paginated, Filtered and Sorted transactions from server
         */
        getAllCompanies() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : "created_at",
                sortOrder: sortDesc[0] ? "desc" : "asc",
            };

            if (this.filter) params["filter"] = this.filter;
            if (this.reseller) params["reseller"] = this.reseller;

            this.loading = true;
            ApiService.get("/admin/companies", { params: params }).then(
                response => {

                    this.loading = false;
                    this.companies = response.data.data.map((company) => {
                        return company;
                    });
                    this.total = response.data.total;

                }
            );
        },
        getResellers(){
            ApiService.get("/admin/resellers?locale=sr").then(
                response => response.data.resellers.forEach(reseller => this.resellers.push(reseller.name))
            );
        },
        /**
         * Filter companies
         */
        filterCompanies: _.debounce(function () {
            this.getAllCompanies();
        }, 500),

        showDetails(item){
            EventBus.$emit('route-update', { title: this.$t('general.company_details') });
            this.$store.state.companyDetails = item;

            this.$router.push({ name: "companyDetails", params:{ id:item.id } })
        },
        editCompany(item) {
            this.curCompany = item
            this.show.editCompany = true;
        },
        onCompanyUpdate(){
            this.show.editCompany = false
            this.curCompany = null
            this.getAllCompanies();
        },
        totalSum(prop){
            let sum = 0;

            for(let i = 0; i < this.companies.length; i++)
                sum += this.companies[i][prop] ? Number(this.companies[i][prop]) : 0;

            return sum;
        },
        async showCompanyLogs(id){
            const response = await ApiService.get(`/admin/companies/${id}/all-activity`);
            this.logs = response.data.activities
            this.show.logs = true
        }
    },
};
</script>

<style lang="scss" scoped>
.companies {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;
}

.edit-company {
    .edit-company-header {
        padding: 16px;
        background: #363c4a;
        color: white;
    }

    .edit-company-content {
        padding: 16px;
        background: white;
    }
}
.sum-td{
    font-weight:bold;
    font-size: 20px !important;
}
.dialog-body{
    background: white;
    padding: 20px;
}
</style>
